import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import Loader from './components/Loader';
import LayoutView from './modules/layout/LayoutView';
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import PublicRoute from './hoc/PublicRoute';
import { isArray } from 'util';
import DialogUtils from './utils/DialogUtils';

interface IAppState {
  isLoading: Boolean;
  errorVisible: Boolean;
}

//include modules
const Login: any = lazyComponentLoader(
  () => import('./modules/auth/login/Login')
);
const Forgot: any = lazyComponentLoader(
  () => import('./modules/auth/forgot-password/ForgotPassword')
);
const Reset: any = lazyComponentLoader(
  () => import('./modules/auth/forgot-password/ResetPassword')
);
const DashboardManagementView: any = lazyComponentLoader(
  () => import('./modules/merchant-management/DashboardManagementView')
);
const CustomerManagementView: any = lazyComponentLoader(
  () => import('./modules/customer-management/CustomerManagementView')
);
const DriverManagementView: any = lazyComponentLoader(
  () => import('./modules/driver-management/DriverManagementView')
);
const ProductManagementView: any = lazyComponentLoader(
  () => import('./modules/product-management/ProductManagementView')
);
const TruckManagementView: any = lazyComponentLoader(
  () => import('./modules/truck-management/TruckManagementView')
);
const OrderManagementView: any = lazyComponentLoader(
  () => import('./modules/order-management/OrderManagementView')
);
const DispatchManagementView: any = lazyComponentLoader(
  () => import('./modules/dispatch-management/DispatchManagementView')
);
const SalesManagementView: any = lazyComponentLoader(
  () => import('./modules/sales-management/SalesManagementView')
);
const DriverBoardingHistoryView: any = lazyComponentLoader(
  () => import('./modules/driver-boarding-history/DriverBoardingHistoryView')
);
const CutoffInventoryView: any = lazyComponentLoader(
  () => import('./modules/begin-end-inventory/CutoffInventoryView')
);

class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);

    //initialize state here
    this.state = {
      isLoading: false,
      errorVisible: true,
    };

    const self = this;
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        self.setState({ isLoading: true });
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.setState({ isLoading: false });

        return response;
      },
      async (error) => {
        if (error.response.data?.errors) {
          this.showError(error.response.data.errors);
        } else if (error.response) {
          this.showError(error);
        }
        self.setState({ isLoading: false });
        return Promise.reject(error);
      }
    );
  }

  showError(errors: any) {
    let content = null;
    if (errors.isArray) {
      content = (
        <div>
          {errors.map((item: any, index: number) => {
            return (
              <div key={Math.random().toString(6)}>
                {item.message.includes('timeout') ||
                item.message.includes('access_token') ||
                item.message.includes('jwt') ||
                item.message.includes('authorization')
                  ? 'Your session has timed out.'
                  : item.message}
              </div>
            );
          })}
        </div>
      );
    } else if (errors.message) {
      content = <div>{errors.message}</div>;
    }
    DialogUtils.error(content);
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <PublicRoute exact path='/login' component={Login} />
            <PublicRoute exact path='/forgot-password' component={Forgot} />
            <PublicRoute exact path='/reset-password' component={Reset} />
            <LayoutView>
              <ProtectedRoute
                exact
                path='/'
                component={DashboardManagementView}
              />
              <ProtectedRoute
                exact
                path='/dashboard'
                component={DashboardManagementView}
              />
              <ProtectedRoute
                exact
                path='/customers'
                component={CustomerManagementView}
              />
              <ProtectedRoute
                exact
                path='/drivers'
                component={DriverManagementView}
              />
              <ProtectedRoute
                exact
                path='/products'
                component={ProductManagementView}
              />
              <ProtectedRoute
                exact
                path='/trucks'
                component={TruckManagementView}
              />
              <ProtectedRoute
                exact
                path='/orders'
                component={OrderManagementView}
              />
              <ProtectedRoute
                path='/daily-route-dispatch'
                component={DispatchManagementView}
              />
              <ProtectedRoute path='/sales' component={SalesManagementView} />
              <ProtectedRoute
                path='/driving-boarding-history'
                component={DriverBoardingHistoryView}
              />
              <ProtectedRoute
                path='/begin-end-inventory'
                component={CutoffInventoryView}
              />
            </LayoutView>
          </Switch>
        </Router>

        {this.state.isLoading ? <Loader /> : null}
      </div>
    );
  }
}

export default App;
