/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT
    ? process.env.REACT_APP_API_ENDPOINT
    : 'http://localhost:3000/api';
  static DEFAULT_TIMEZONE: string = process.env.REACT_APP_DEFAULT_TIMEZONE
    ? process.env.REACT_APP_DEFAULT_TIMEZONE
    : 'Asia/Manila';

  // REACT_APP_GOOGLE_MAPS_API_KEY
  static REACT_APP_GOOGLE_MAPS_API_KEY: string = process.env
    .REACT_APP_GOOGLE_MAPS_API_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    : '';

  // REACT_APP_SOCKET_URL
  static SOCKET_URL: string = process.env.REACT_APP_API_SOCKET_URL
    ? process.env.REACT_APP_API_SOCKET_URL
    : '';

  static MODE: string = process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE
    : '';
}

export default APPConfig;
