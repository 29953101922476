import React from 'react';
import { Modal } from 'antd';
import ModalContent from '../components/ModalContent';

export default class DialogUtils {
  /**
   *
   * @param content
   * @param onOk
   * @param onCancel
   */
  static confirm(
    content: any,
    onOk?: Function,
    onCancel?: Function,
    okText?: string,
    cancelText?: string,
    title?: string,
    warning?: boolean,
    exclamation?: boolean
  ) {
    Modal.confirm({
      icon: null,
      centered: true,
      okButtonProps: {
        className: warning ? 'ant-btn-danger' : '',
      },
      cancelButtonProps: {
        className: 'ant-btn-cancel',
      },
      okText: okText ? okText : 'Ok',
      content: (
        <ModalContent
          type={'confirm'}
          content={content}
          title={title}
          warning={warning}
        />
      ),
      onOk: () => {
        if (onOk) onOk();
      },
      onCancel: () => {
        if (onCancel) onCancel();
      },
    });
  }

  /**
   *
   * @param content
   * @param onOk
   */
  static error(content: any, onOk?: Function) {
    Modal.error({
      icon: null,
      centered: true,
      className: 'error-modal',
      okButtonProps: {
        className: 'modalErrorDialog',
      },
      content: <ModalContent type={'error'} content={content} />,
      onOk: () => {
        if (onOk) onOk();
      },
    });
  }

  /**
   *
   * @param content
   * @param onOk
   */
  static success(
    content: any,
    onOk?: Function,
    title?: string,
    okText?: string
  ) {
    Modal.success({
      icon: null,
      centered: true,
      className: 'success-modal',
      okButtonProps: {
        id: 'modal-success-btn',
        className: 'modalSucessDialog',
      },
      okText: okText ? okText : 'Ok',
      content: (
        <ModalContent type={'success'} content={content} title={title} />
      ),
      onOk: () => {
        if (onOk) onOk();
      },
    });
  }
}
