import React from 'react';
import { Typography } from 'antd';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

const ERROR_COLOR = '#E74141';
const SUCCESS_COLOR = '#15C668';

const iconStyle = { fontSize: 22 };

interface ModalContentProps {
  title?: string;
  content: HTMLElement;
  type: 'success' | 'error' | 'confirm';
  warning?: boolean;
}

const { Title } = Typography;

const ModalError = () => {
  return (
    <div className='displayFlex'>
      <div className='modalIconContainer'>
        <div>
          <CloseCircleFilled style={{ ...iconStyle, color: ERROR_COLOR }} />
        </div>
      </div>
      <Title
        level={3}
        style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}
      >
        Error
      </Title>
    </div>
  );
};

const ModalSuccess = (props: any) => {
  return (
    <div className='displayFlex'>
      <div className='modalIconContainer'>
        <CheckCircleFilled style={{ ...iconStyle, color: SUCCESS_COLOR }} />
      </div>
      <Title
        level={3}
        style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}
      >
        {props.title ? props.title : 'Success'}
      </Title>
    </div>
  );
};

const ModalConfirm = (props: any) => {
  return (
    <div className='displayFlex'>
      <div className='modalIconContainer'>
        <div>
          {props.warning ? (
            <CloseCircleFilled style={{ ...iconStyle, color: ERROR_COLOR }} />
          ) : (
            <ExclamationCircleFilled
              style={{ ...iconStyle, color: SUCCESS_COLOR }}
            />
          )}
        </div>
      </div>
      <Title
        level={3}
        style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}
      >
        {props.title ? props.title : 'Confirm'}
      </Title>
    </div>
  );
};

const ModalContent = (props: ModalContentProps) => {
  let RenderModalType;
  switch (props.type) {
    case 'error':
      RenderModalType = ModalError;
      break;
    case 'success':
      RenderModalType = ModalSuccess;
      break;
    case 'confirm':
      RenderModalType = ModalConfirm;
      break;
  }
  return (
    <div className='fuelModalContainer'>
      {RenderModalType && (
        <RenderModalType title={props.title} warning={props.warning} />
      )}
      <div className='modalDialogContent text-lightgray'>{props.content}</div>
    </div>
  );
};

export default ModalContent;
