import React, { useState } from 'react';
import { Layout, Col } from 'antd';
import SideMenuView from './sidebar/SideMenuView';
import HeaderView from './header/HeaderView';

const { Sider, Content } = Layout;

const LayoutView = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 820);
  const [pageHeader, setPageHeader] = useState('');

  const toggleCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };
  const child = React.Children.map(children, (child) => {
    return React.cloneElement(child, { setPageHeader: setPageHeader });
  });
  return (
    <Layout>
      <Sider
        width={220}
        theme='light'
        trigger={null}
        className='sidebar'
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapse}
      >
        <SideMenuView
          siderCollapsed={collapsed}
          collapseToggle={toggleCollapse}
          style={{ background: '#ffffff' }}
        />
      </Sider>
      <Layout className='main-layout'>
        <HeaderView
          siderCollapsed={collapsed}
          collapseToggle={toggleCollapse}
          pageHeader={pageHeader}
        />
        <Content
          style={{
            padding: 24,
            paddingTop: 0,
            minHeight: 'calc(100vh - 84px)',
            maxHeight: 'calc(100vh - 84px)',
            overflow: 'auto',
          }}
        >
          {child}
        </Content>
        {/* <FooterView /> */}
      </Layout>
    </Layout>
  );
};

export default LayoutView;
