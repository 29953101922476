import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import { ReactComponent as IconLogout } from '../../../../src/assets/img/svg/IconLogout.svg';
import socketIOClient from 'socket.io-client';

import './HeaderView.less';

const HeaderView = (props: any) => {
  const [user, setUser]: any = useState();

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      onLogout();
    } else {
      const data: any = localStorage.getItem('user');
      setUser(JSON.parse(data));
    }
  }, []);

  const onLogout = async () => {
    localStorage.clear();
    props.history.push('/login');
  };
  const toggleSidebar = () => {
    props.collapseToggle(!props.siderCollapsed);
  };

  return (
    <>
      <Row
        justify='space-between'
        className='padding-m bg-white text-gray border-primary'
      >
        <Col>
          {
            !props.siderCollapsed ?
            <LeftOutlined onClick={toggleSidebar} /> :
            <RightOutlined onClick={toggleSidebar} />
          }
        </Col>
        <Col>
          <span onClick={() => onLogout()} className='c-pointer displayFlex'>
            <IconLogout className='img-icon-primary margin-right-s' />
            Log Out
          </span>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(HeaderView);
